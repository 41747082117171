// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  // -webkit-overflow-scrolling: touch;
}

body {
  overflow-x: hidden;
  position: relative;
  // -webkit-overflow-scrolling: touch;
}

img {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

img:before {
  // content: "" " Broken Image of " attr(alt);
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  background-image: url(../../assets/null-img.svg);
  background-position: top;
  background-size: cover;
  border-radius: 0px 5px 5px 5px;
}

// img:after {
//   content: "" " Broken Image of " attr(alt);
//   display: block;
//   font-size: 12px;
//   width: 100%;
//   height: 100%;
// }

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

hr {
  border: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(-1);
  background-image: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 20px 0;
}

select {
  outline: 0;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url(/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 24px);
  background-size: 10px;
  border: 1px solid #f1f1f1;
  padding: 8px 20px;
}
