@import "~stylesheets/base/keyframes";
@import "~stylesheets/abstracts/mixins";

.call-to-action {
  @include for-phone-only {
    height: auto;
    min-height: auto;
    padding: 20px 0;
  }

  @include for-tablet-landscape {
    padding: 80px 0;
  }

  @include for-tablet-portrait {
    padding: 50px 0;
  }

  padding: 100px 0;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  height: 720px;
  min-height: 720px;

  &.has-background-color {
    position: relative;
    z-index: 2;
    background-color: rgba(#4697e8, 0.05);
  }

  &__inner {
    @include for-phone-only {
      flex-direction: column;
      padding: 0;

      &.reverse {
        flex-direction: column-reverse;

        .column-images {
          margin: 15px 0 !important;
        }
        .column-text {
          padding: 30px 0 !important;
        }
      }

      &.mobile-top-title {
        position: relative;
        padding-top: 85px;
        h3 {
          position: absolute;
          top: 20px;
          left: 0;
        }
      }
    }

    display: flex;
    // padding: 0 20px;

    .column-text {
      @include for-phone-only {
        flex: 100% 0;
        padding: 20px 0 !important;
      }

      @include for-tablet-portrait {
        padding: 0 40px 0 20px;
      }

      font-family: "Montserrat";
      padding: 0 100px 0 20px;
      flex: 55% 0;

      .text-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        width: 100%;

        & > h3 {
          @include for-phone-only {
            display: block;
            width: 100%;
            font-size: 26px;
            margin: 0 0 20px 0;
          }

          @include for-tablet-portrait {
            font-size: 32px;
          }

          @include for-tablet-landscape {
            font-size: 36px;
          }

          font-size: 42px;
          color: #424242;
          font-weight: 600;
          // text-transform: uppercase;
          margin: 30px 0;
        }

        & > p {
          @include for-phone-only {
            display: block;
            width: 100%;
            margin: 0 auto;
            font-size: 18px;
          }

          @include for-tablet-portrait {
            font-size: 18px;
          }

          @include for-tablet-landscape {
            font-size: 18px;
          }

          color: #8e8e8e;
          line-height: 35px;
          font-size: 20px;
          font-weight: 400;
          margin: 0;
          color: #8e8e8e;
        }

        .button {
          @include for-phone-only {
            margin: 30px auto 0;
            width: 100%;
          }

          margin-top: 30px;
          display: inline-block;
        }
      }

      &.column-text--bottom {
        @include for-phone-only {
          padding-left: 20px;
        }

        @include for-tablet-portrait {
          padding-left: 60px;
        }

        align-items: flex-end;
        display: flex;
        padding: 20px;
        padding-left: 100px;
      }
    }

    .column-images {
      @include for-phone-only {
        flex: 100% 0;
        top: 0 !important;
        max-height: 300px;
        min-height: 300px;
        margin: 30px 0;
        background-color: transparent;
      }

      &.top-space {
        top: 60px;
      }

      flex: 45% 0;
      position: relative;
      min-height: 500px;

      .card-ctn {
        position: relative;
        width: 100%;
        height: 100%;

        @include for-phone-only {
          height: 300px;
        }

        .half-card {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 33%;
          img {
            width: 100%;
            border: 1px solid #f4f4f4;
            border-left: none;
            border-radius: 5px;
          }
          @include for-phone-only {
            left: 0;
            max-height: 300px;
            height: 100%;
            display: flex;
            align-items: center;

            img {
              max-height: 100%;
            }
          }
        }

        .full-card {
          position: absolute;
          left: 36%;
          top: 50%;
          transform: translateY(-50%);
          width: 52%;
          img {
            box-shadow: 0px 15px 70px #d2d7e485;
            width: 100%;
            border: 1px solid #f1f1f1;
            // border-left: none;
            border-radius: 5px;
            box-sizing: border-box;
          }
          @include for-phone-only {
            left: calc(33% + 10px);
            max-width: 300px;
            max-height: 300px;
            height: 100%;
            display: flex;
            align-items: center;

            img {
              max-height: 100%;
            }
          }
        }
      }

      .small-card {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-120%);
        z-index: 4;
        width: 43%;
        img {
          box-shadow: 3px 10px 85px rgba(194, 202, 219, 0.4);
          width: 100%;
        }

        @include for-phone-only {
          // width: 50%;
          min-width: 150px;
          width: 33%;
          top: 0;
          transform: translateY(-30px);

          img {
            width: 100%;
          }
        }
      }

      .next-deal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @include for-phone-only {
          height: 100%;

          img {
            object-fit: contain;
            height: 100%;
          }
        }

        img {
          width: 100%;
          // height: 100%;
        }
      }

      .instantly-assignable {
        position: relative;
        width: 100%;
        height: 100%;

        @include for-phone-only {
          height: 300px;
          // overflow-x: hidden;
        }

        .single-property-card {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: 3;
          width: 51%;
          img {
            box-shadow: 0 10px 50px #e1e1e1;
            width: 100%;
          }

          @include for-phone-only {
            top: 50%;
            height: 100%;
            left: 35%;
            transform: translate(-50%, -50%);
            max-width: 200px;
            display: flex;
            align-items: center;

            img {
              // height: 100%;
              width: 100%;
            }
          }
        }

        .property-info {
          position: absolute;
          top: 50%;
          right: 3%;
          width: 320px;
          transform: translateY(-149%);
          width: 43%;
          z-index: 3;

          img {
            width: 100%;
            box-shadow: 0 10px 50px #e1e1e1;
          }

          @include for-phone-only {
            width: 30%;
            min-width: 150px;
            right: 20%;
            transform: translateY(-152%) translateX(30%);
          }
        }

        .buyer-info {
          position: absolute;
          top: 50%;
          right: 12%;
          // width: 320px;
          transform: translateY(-58%);
          width: 43%;
          z-index: 3;

          img {
            width: 100%;
            box-shadow: 0 10px 50px #e1e1e1;
          }

          @include for-phone-only {
            width: 30%;
            min-width: 150px;
            right: 20%;
            transform: translateY(-92%) translateX(5%);
          }
        }

        .offer-info {
          position: absolute;
          top: 50%;
          right: 4%;
          // width: 320px;
          width: 43%;
          transform: translateY(87%);
          z-index: 3;

          img {
            width: 100%;
            box-shadow: 0 10px 50px #e1e1e1;
          }

          @include for-phone-only {
            width: 30%;
            min-width: 150px;
            right: 12%;
            transform: translateY(57%) translateX(6%);
          }
        }

        .founding-info {
          position: absolute;
          top: 50%;
          right: 11%;
          width: 43%;
          transform: translateY(274%);
          z-index: 3;

          img {
            width: 100%;
            box-shadow: 0 10px 50px #e1e1e1;
          }

          @include for-phone-only {
            width: 30%;
            min-width: 150px;
            right: 20%;
            transform: translateY(230%) translateX(10%);
          }
        }

        .connect-dots {
          position: absolute;
          top: 50%;
          right: -30px;
          width: 100px;
          max-height: 503px;
          transform: translateY(-43%);
          width: 17%;
          z-index: 3;

          img {
            width: 100%;
            max-height: 100%;
          }

          @include for-phone-only {
            width: 12%;
            right: 17%;
            min-width: 65px;
            transform: translateY(-50%) translateX(100%);
          }
        }
      }

      .grid-images-ctn {
        width: 100%;
        height: 100%;
      }

      .grid-images {
        max-width: 530px;
        width: 100%;
        margin-left: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 10px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        @include for-phone-only {
          max-height: 300px;
        }

        .grid-image {
          overflow: hidden;
          position: relative;
          z-index: 2;
          background-color: #fff;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &.grid-image--one {
            grid-column: 1/ 2;
            grid-row: 1/2;
          }

          &.grid-image--two {
            grid-column: 2/3;
            grid-row: 1/3;
          }

          &.grid-image--three {
            grid-column: 1/2;
            grid-row: 2/4;
          }

          &.grid-image-four {
            grid-column: 2/3;
            grid-row: 3/4;
          }
        }
      }

      .property-imgs {
        position: absolute;
        top: -64px;
        left: -5%;
        z-index: 3;
        width: 65%;
        max-width: 300px;

        svg {
          // box-shadow: 10px 65px 99px #DCE5EF;
          width: 100%;
        }

        @include for-phone-only {
          transform: scale(0.7);
          min-width: 150px;
        }
      }

      .rubik-icon {
        position: absolute;
        top: 0;
        right: 0;
        box-shadow: 0 10px 60px rgba(#000000, 0.05);
        transform: translate(50%, -50%);
        background: #4697e8;
        z-index: 3;
        width: 66px;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        @include for-phone-only {
          display: none;
        }
      }

      .dots {
        position: absolute;
        bottom: 0;
        left: -15px;
        left: 0;

        @include for-phone-only {
          display: none;
        }
      }

      .exclusive-tag {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #f6fafd;
        padding: 25px;
        font-size: 22px;
        font-weight: 600;
        color: #3772e3;
        z-index: 5;
        border-radius: 5px;
        transform: translateY(100%);

        @include for-phone-only {
          display: none;
        }
      }
    }
  }
}

.icon-decoration {
  @include for-phone-only {
    display: none;
  }

  background: #fff;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 25px 30px rgba(#356fe3, 0.1);
}

.start-animate {
  .grid-images {
    .grid-image {
      animation-name: fadeInLeft;
      animation-duration: 0.8s;
      animation-fill-mode: both;

      &.grid-image--one {
        animation-delay: 0.8s;
      }
      &.grid-image--two {
        animation-delay: 1s;
      }
      &.grid-image--three {
        animation-delay: 1.3s;
      }

      &.grid-image--four {
        animation-delay: 1.6s;
      }
    }
  }
  .property-imgs {
    @extend .fadeInUp;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  .small-card {
    img {
      @extend .bounceIn;
      animation-duration: 1s;
      animation-fill-mode: backwards;
      animation-delay: 1.5s;
    }
  }

  .instantly-assignable {
    .property-info,
    .buyer-info,
    .offer-info,
    .founding-info {
      img {
        animation-name: fadeInLeft;
        animation-duration: 0.6s;
        animation-timing-function: ease-in;
        animation-fill-mode: both;
      }
    }

    .property-info {
      img {
        animation-delay: 0.5s;
      }
    }

    .buyer-info {
      img {
        animation-delay: 1s;
      }
    }

    .offer-info {
      img {
        animation-delay: 1.3s;
      }
    }
    .founding-info {
      img {
        animation-delay: 1.6s;
      }
    }
  }

  .icon-decoration {
    @extend .fadeInLeft;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  p,
  h3 {
    @extend .fadeInLeft;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    animation-fill-mode: both;
  }

  h3 {
    animation-delay: 0.4s;
  }

  p {
    animation-delay: 0.8s;
  }
}
