/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
      float: right;
  }
  img {
      display: block;
  }
  &.slick-loading img {
      background: #333;
  }

  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

// .slick-arrow {
//   position: absolute;
//   z-index: 9;
//   background: none;
//   width: 88px;
//   height: 40px;
//   background-color: #3369E2;
//   font-size: 0;
//   bottom: 4px;
//   border: 0;
//   outline: 0;
//   transition: opacity .3s ease-in-out;
//   cursor: pointer;

//   &.slick-disabled {
//     opacity: .2;
//     cursor: not-allowed;

//     &:hover {
//       opacity: .2;
//     }
//   }

//   &:hover {
//     opacity: 1;
//   }
  
//   &::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 0;
//     margin: 0  auto;
//     background-position: center center;
//     background-repeat: no-repeat;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   &.slick-prev {
//     left: 50%;

//     &::before {
//       background-image: url(/slider/arrow-prev.svg);
//     }
//   }

//   &.slick-next {
//     right: 50%;

//     &::before {
//       background-image: url(/slider/arrow-next.svg);
//     }
//   }
// }

.slick-dots {
  display: block;
  width: 100%;
  text-align: center;
  padding: 13px 20px 13px;
  
  li {
    display: inline-flex;
    padding: 0 8px;

    button {
      opacity: .6;
      outline: 0;
      padding: 0;
      font-size: 0;
      border: 0;
      width: 8px;
      height: 8px;
      border-radius: 12px;
      transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
      background-color: #3369E2;
      cursor: pointer;
      transform-origin: center center;
      transform: scale(1);
    }

    &.slick-active {
      button {
        opacity: 1;
        transform: scale(1.8);
      }
    }
  }
}