// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

.container {
  max-width: $max-width;
  // width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;

  @include for-phone-only {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.row {
  margin-left: -20px;
  margin-right: -20px;
}

.hide-text {
  overflow: hidden;
  padding: 0;
  text-indent: 101%;
  white-space: nowrap;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.main-title {
  margin-left: 6.5%;
  width: calc(100% - 6.5% * 2);
  margin-bottom: 35px;

  &.no-margin-left {
    margin-left: 0;
  }

  &--has-bottom-line {
    position: relative;

    &::after {
      content: "";
      width: 100%;
      //max-width: 260px;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        #383838 0%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      bottom: -11px;
      left: 0;
    }
  }

  h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #f0f0f0;
    letter-spacing: 1px;
  }
}

.second-title {
  color: #333;

  a {
    color: #333;

    &:hover {
      color: #535353;
    }
  }

  &--has-bottom-line {
    position: relative;
    margin-bottom: 28px;
    margin-top: 18px;

    &::after {
      content: "";
      width: 100%;
      max-width: 150px;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        #333 0%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      bottom: -11px;
      left: 0;
    }
  }
}

.modal-open {
  overflow: hidden;
}

.gradient-bg,
.property-active {
  background-image: $gradient-bg !important;
}

.property-sold {
  background-color: #f43d61 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
