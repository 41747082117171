.page {
  &.page--landing {
    position: relative;
    background: #fff;
    margin-top: 90px;

    @include for-phone-only {
      margin-top: 65px;
    }
    
  }

  &.page-reset-password {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.page-listing {
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    height: calc(100vh - 95px);
    top: 90px;
    z-index: 50;

    @include for-phone-only {
      top: 63px;
      height: calc(100vh - 63px);
    }
  }

  &.page--details {
    position: relative;
    height: 100%;
    top: 86px;
  }

  &.page--faq {
    position: relative;
    margin-top: 90px;
    background-color: #F8FCFF;
    min-height: 100vh;

    @include for-phone-only {
      margin-top: 65px;
    }
  }

  &.page--about {
    position: relative;
    margin-top: 90px;
    background-color: #fff;

    @include for-phone-only{
      margin-top: 65px;
    }
  }

  &.page--dashbaord {
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: calc(100vh - 90px);
    top: 90px;
    z-index: 50;

    @include for-phone-only{
      height: calc(100vh - 65px);
      top: 65px;
    }
  }
}
