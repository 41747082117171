.section-margin-top {
  margin-top: 70px;
}

.section-no-margin-bottom {
  margin-bottom: 0;
}

.section-margin-bottom {
  margin-bottom: 70px;
}

.section-no-margin-top {
  margin-top: 0;
}