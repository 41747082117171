@import "~stylesheets/abstracts/mixins";

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  min-width: 360px;
  box-sizing: border-box;
  padding: 15px 25px;
  margin: 0 15px;
  background-color: rgb(236, 200, 197);
  border: 1px solid #ac8885;
  max-width: 560px;

  &.green{
    background-color: rgb(17, 202, 41);

    > div{
      color: #fff;
    }
  }

  & > div {
    font-family: "Montserrat";
    font-weight: 500;
    color: #a24542;
    display: flex;
    white-space: initial;

    & svg {
      width: 20px;
      height: 20px;
      position: relative;
      left: -15px;
    }
  }

  @include for-phone-only {
    min-width:unset;
    width:90vw;
    max-width:360px;
    text-align: center;
  }
}
