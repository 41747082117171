$blue: #3369e2;

.loading-items-holder {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25vh 0;

  &.hide {
    display: none;
  }

  &.small-width{
    width: 50%;
  }

  .loading-items {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0;
    display: flex;

    li {
      height: 12px;
      width: 12px;
      margin: 4px;
      background: $blue;
      border-radius: 100%;
      transform: transformZ(0);
      animation: loadingDotted 1s infinite;

      &:nth-child(1n) {
        left: -20px;
        animation-delay: 0s;
      }
      &:nth-child(2n) {
        left: 0;
        animation-delay: 0.2s;
      }
      &:nth-child(3n) {
        left: 20px;
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes loadingDotted {
  0% {
    transform: scale(0.5);
    color: #3369e2;
  }
  50% {
    transform: scale(1);
    color: rgb(108, 154, 255);
  }
  100% {
    transform: scale(0.5);
    color: #3369e2;
  }
}
