@import "~stylesheets/abstracts/mixins";
@import "~stylesheets/abstracts/variables";

.popup-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat";
  max-width: 1440px;
  width: 100%;
  height: calc(100vh - 120px);
  padding: 0 20px;
  padding-bottom: 0;
  color: #424242;

  @include for-phone-only {
    padding: 0;
    height: calc(100vh - 50px);
  }
}

.submit-offer-tracker {
  width: 100%;
  // background: red;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  position: relative;

  @include for-phone-only {
    display: none;
  }

  & .tracker-line {
    width: 100%;
    height: 4px;
    background-color: rgba(#4696e8, 0.1);
    position: absolute;
    top: 10px;
    left: 0;

    span {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #4696e8;
      height: 100%;
      width: 0;
      display: inline-block;
    }
  }

  > div {
    justify-self: center;
    font-size: 18px;
    font-weight: 500;
    color: #525252;
    position: relative;
    padding-top: 35px;

    &.active {
      &::before {
        background-color: #4697e8;
      }
    }

    &::before {
      content: "";
      width: 26px;
      height: 26px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #cfe0f7;
    }

    &:first-of-type {
      justify-self: left;
    }
    &.center-circle {
      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    // &.right-circle {
    //   &::before {
    //     left: unset;
    //     right: 0;
    //   }
    // }
    &:last-of-type {
      justify-self: right;
      text-align: right;
      &::before {
        left: unset;
        right: 0;
      }
    }
  }
}

.submit-offer {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 5px;
  overflow-x: hidden;

  & + .alert {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    animation-name: from-top;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;

    > div:nth-of-type(2) {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  @include for-phone-only {
    // margin: 0;
    padding: 0px 20px 0px 35px;
    margin-top: 45px;

    > div {
      position: relative;

      &::before {
        content: "";
        height: calc(100% - 70px);
        width: 4px;
        background-color: rgba(#4696e8, 0.1);
        position: absolute;
        top: 35px;
        // bottom: 45px;
        left: -20px;
      }
    }
  }

  &-process {
    background-color: white;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    @include for-phone-only {
      position: relative;

      &:last-of-type {
        &::before {
          top: unset;
          bottom: 35px;
        }
      }
      &::before {
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 50%;
        position: absolute;
        top: 35px;
        left: -28px;
        background-color: #cfe0f7;
      }
    }

    &.closed {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include for-phone-only {
        // flex-wrap: wrap;
      }

      @include for-phone-only {
        .submit-offer-process-body {
          padding-left: 0;
        }
      }

      .property-details {
        display: none;
      }

      .close-btn {
        display: none !important;
      }

      .submit-offer-process-body {
        border-top: none !important;

        .open-btn {
          margin: unset !important;
        }

        .body-inputs {
          display: none;
        }
      }
    }

    &-title {
      padding: 35px;
      display: flex;
      align-items: center;
      position: relative;

      @include for-phone-only {
        padding: 15px;
      }

      .number {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #cfe0f7;
        border-radius: 50%;
        font-size: 12px;
        color: #424242;
        margin-right: 20px;

        &.active {
          background-color: #4697e8;
          color: #fff;
        }
      }

      h3 {
        font-size: 26px;
        font-weight: 600;
        color: #424242;

        @include for-phone-only {
          font-size: 16px;
        }
      }

      .close-btn {
        position: absolute;
        top: 35px;
        right: 35px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f9fc;
        cursor: pointer;

        @include for-phone-only {
          display: none;
        }
      }
    }

    &-body {
      padding: 25px 35px;
      border-top: 1px solid #f0f6fa;

      @include for-phone-only {
        padding: 15px;
      }

      .property-details {
        display: flex;
        justify-content: flex-start;

        .property-img {
          width: 150px;
          height: 150px;
          border-radius: 5px;
          overflow: hidden;

          @include for-phone-only {
            width: 40%;
            // max-width: 80px;
            height: unset;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .property-info {
          margin-left: 35px;
          margin-right: 10px;

          @include for-phone-only {
            width: 60%;
            margin-left: 15px;
          }

          .address {
            font-size: 20px;
            font-weight: 600;
            color: #525252;

            @include for-phone-only {
              font-size: 15px;
            }
          }

          .price {
            font-size: 20px;
            font-weight: 600;
            color: #4d4d4d;
            margin-top: 50px;

            @include for-phone-only {
              font-size: 15px;
            }
          }

          .review-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            > div {
              font-size: 20px;
              font-weight: 600;
              color: #555555;
              margin-top: 30px;

              @include for-phone-only {
                font-size: 14px;
              }

              &:not(:last-child) {
                margin-right: 70px;
              }
            }

            .offer-price {
              color: #3671e3;
            }
          }
        }
      }

      &.body-inputs-ctn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;

        @include for-phone-only {
          flex-direction: column;
        }

        .body-inputs {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 20px;
          flex: 1;
          padding: 5px 0;

          @include for-phone-only {
            justify-content: center;
          }

          @include for-tablet-portrait {
            justify-content: center;
          }

          @include for-tablet-landscape {
            justify-content: center;
          }

          // margin-right: 10px;

          .input-field {
            width: 100%;
            font-size: 16px;
            max-width: 280px;
            font-weight: 400;
            outline: none;
            background-color: #f6f9ff;
            color: inherit;
            border-radius: 3px;
            // margin-right: 20px;

            input {
              padding: 20px;
              background-color: inherit;
            }
          }

          .select-ctn {
            width: 100%;
            // margin-right: 20px;
            height: 55px;
            max-width: 280px;
            cursor: pointer;
            position: relative;

            &.input-error {
              &::after {
                content: "Please fill this form";
                position: absolute;
                bottom: -20px;
                right: 0;
                font-size: 12px;
                color: red;

                @include for-phone-only {
                  bottom: -18px;
                  font-size: 10px;
                }
              }
            }

            .css-2b097c-container {
              width: 100%;
              // margin-right: 20px;
              height: 55px;
              max-width: 280px;
              cursor: pointer;

              .css-yk16xz-control,
              .css-1pahdxg-control {
                min-height: 100%;
                cursor: pointer;
              }
            }
          }

          .static-info {
            width: 100%;
            background-color: rgba(#f0f0f0, 0.6);
            // margin-right: 20px;
            display: flex;
            max-width: 280px;
            align-items: center;
            justify-content: space-between;
            height: 55px;
            padding: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #555555;
            border-radius: 4px;
            overflow: hidden;

            span:first-of-type {
              margin-right: 5px;
            }

            &.proof-of-founds {
              background: transparent;
              border: 1px dashed #d5e0f9;
              cursor: pointer;

              input {
                display: none;
              }
            }
          }
        }
      }

      &.review-body {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-phone-only {
          flex-direction: column;

          .open-btn {
            // margin-top: 20px;
            margin: 20px auto 0 0;
            // margin-right: 10%;
          }
        }
      }

      .open-btn {
        padding: 18px 30px;
        background-image: linear-gradient(
          270deg,
          rgba(70, 151, 232, 0.1),
          rgba(51, 105, 226, 0.1)
        );
        color: #3d81e5;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        border: none;
        outline: none;
        box-shadow: none;
        transition: all 0.3 ease;

        &:hover {
          background-image: $gradient-bg;
          color: #fff;
        }

        @include for-phone-only {
          max-width: 280px;
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
}

.check-email-popup {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 480px;

  @include for-phone-only {
    padding: 0 20px;
  }

  &--inner {
    text-align: center;
    width: 100%;
    padding: 100px 50px;
    background-color: #ffffff;
    box-shadow: 0 37px 80px rgba(#346be3, 0.1);
    border-radius: 5px;

    @include for-phone-only {
      padding: 50px 20px;
    }

    h3 {
      font-size: 36px;
      font-weight: 600;
      color: #424242;
      margin-top: 40px;

      @include for-phone-only {
        font-size: 28px;
        margin-top: 20px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 500;
      color: #555;
      margin-top: 20px;

      @include for-phone-only {
        font-size: 16px;
      }
    }

    button {
      width: 120px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: $gradient-bg;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      margin: 40px auto 0 auto;
      outline: none;
      border: none;
      border-radius: 5px;

      @include for-phone-only {
        margin-top: 20px;
      }
    }
  }
}

@keyframes from-top {
  from {
    top: -20px;
  }
  to {
    top: 20px;
  }
}
