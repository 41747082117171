// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  @include for-phone-only {
    padding: 50px 0px;
  }

  padding: 100px 20px;
  background-color: #fff;

  &.has-bg-image {
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: right;
  }

  &.is-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .columns-footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    grid-gap: 10px;

    @include for-tablet-portrait {
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
    }

    @include for-tablet-landscape {
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
    }

    @include for-phone-only {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px;
    }

    .column-3 {
      @include for-phone-only {
        text-align: center;
      }
      h5 {
        font-family: "Montserrat";
        font-weight: 500;
        margin-bottom: 50px;
        font-size: 24px;
        text-transform: uppercase;
        color: #424242;

        @include for-tablet-landscape {
          font-size: 20px;
          margin-bottom: 30px;
        }

        @include for-tablet-portrait {
          font-size: 20px;
          margin-bottom: 30px;
        }

        @include for-phone-only {
          margin-bottom: 10px;
          font-size: 20px;
        }
      }

      .footer-links {
        font-family: "Montserrat";
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          margin-bottom: 20px;

          @include for-phone-only {
            margin-bottom: 10px;
          }

          & a {
            font-family: "Montserrat";
            color: #424242;
            font-size: 20px;
            font-weight: 500;

            @include for-tablet-landscape {
              font-size: 18px;
            }

            @include for-tablet-portrait {
              font-size: 18px;
            }

            @include for-phone-only {
              font-size: 16px;
            }
          }
        }
      }

      .footer-social-media {
        @include for-phone-only {
          display: inline-flex;
          text-align: center;
          margin-top: 0;
          margin-bottom: 20px;
        }
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;

        li {
          margin-right: 20px;
          @include for-phone-only {
            margin-right: 35px;
            &:last-of-type {
              margin-right: 0 !important;
            }
          }

          a {
            background-color: transparentize($color: #3369e2, $amount: 0.9);
            width: 54px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #225bdc;
            transition: color 0.3s ease, background-color 0.3s ease;

            &:hover {
              background-color: none;
              background-image: $gradient-bg;
              color: #fff;
            }

            @include for-phone-only {
              width: 32px;
              height: 32px;
            }
          }

          .social-footer-icon {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.copyright-description {
  color: #424242;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  margin-top: 100px;

  @include for-tablet-landscape {
    margin-top: 70px;
  }

  @include for-tablet-portrait {
    margin-top: 70px;
  }

  @include for-phone-only {
    margin-top: 20px;
    text-align: center;
  }
}

.footer-address {
  // margin-top: 5px;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: #424242;
  font-family: "Montserrat";

  span {
    // line-height: 50px;
    display: block;
    margin-top: 20px;

    @include for-phone-only {
      margin-top: 10px;
    }
  }

  @include for-tablet-landscape {
    font-size: 18px;
  }

  @include for-tablet-portrait {
    font-size: 18px;
  }

  @include for-phone-only {
    font-size: 16px;
    line-height: 30px;
  }
}
