.user-image {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: #d2dbf4;
  margin-left: 50px;
  box-shadow: 2px 2px 5px rgba(123, 123, 123, 0.22);
  border: 3px solid #fff;
  position: relative;

  &.user-image--circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3369e2;
    font-size: 14px;
    font-weight: 600;
    font-family: "Montserrat";
  }

  &.notification-show{
    &::after{
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%,-50%);
      background-color: #3369e2;
    }
  }
}
