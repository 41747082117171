// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

//@import '~stylesheets/abstracts/_mixins.scss';

.button {
  text-align: center;
  padding: 21px 50px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 3;
  background: #fff;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 4px;
    z-index: -2;
    transition: opacity 0.2s;
    font: inherit;
  }

  @include for-phone-only {
    padding: 18px 25px;
    font-size: 12px;
  }

  &--default {
    color: #fff;
    background-image: $gradient-bg;

    &::before {
      background-image: $gradient-bg;
    }

    &:hover,
    &:focus {
      color: $brand-color;
      box-shadow: 0px 2px 10px rgba(#000, 0.1);

      &::before {
        background-image: none;
        background-color: #fff;
      }
    }
  }

  &--light {
    color: #3369e2;
    background-image: $gradient-bg;

    &::before {
      background-color: #fff;
    }

    &:hover,
    &:focus {
      color: #fff;
      box-shadow: 0px 2px 10px rgba(#000, 0.1);

      &::before {
        background-color: transparent;
      }
    }
  }

  &--red {
    color: #f43d61;

    &::before {
      background-color: #fff;
    }

    &:hover,
    &:focus {
      color: #fff;
      box-shadow: 0px 2px 10px rgba(#000, 0.1);

      &::before {
        background-color: #f43d61;
      }
    }
  }

  &--outline {
    border: 1px solid #fff;
    color: #fff;
    font-weight: 400;
    background-color: transparent;
  }

  &--link {
    border: 1px solid #454545;
    color: #aaaaaa;
    padding: 9px 22px;
  }

  &--has-icon {
    position: relative;
    padding-left: 65px;

    & > i {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      border-right: 1px solid #c4333e;
    }
  }

  &--circle {
    border-radius: 50%;
  }

  &--rounded {
    border-radius: 35px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
