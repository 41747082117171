@import "~stylesheets/abstracts/mixins";

.arrow-box {
  position: absolute;
  background: white;
  width: 210px;
  right: 0;
  margin-top: 12px;
  border-radius: 3px;
  padding: 5px 0;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);

  @include for-phone-only {
    position: fixed;
    top: 0;
    left: 0;
    background: #f5f9fc;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px !important;
    // transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000 !important;
    margin: 0;
  }

  &::after,
  &::before {
    bottom: 100%;
    right: 12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(136, 183, 213, 0);
    border-width: 0px;
    margin-left: -0px;
  }

  &::before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: white;
    border-width: 5px;
    margin-left: -5px;
  }

  & > * {
    padding: 10px;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    display: block;

    span {
      display: none;
    }

    &.active {
      color: #346be3;
    }

    &.notification {
      position: relative;
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(50%, -50%);
        background-color: #3369e2;
      }
    }

    @include for-phone-only {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding: 25px 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid rgba(#346ce4, 0.05);

      &.active {
        background-color: #fff;
        span {
          background-color: #fff;
          box-shadow: 0 10px 30px rgba(#346be3, 0.15);
        }
      }

      span {
        display: block;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        background: #eff4fc;
      }
      &.log-out {
        display: none;
      }
    }

    &:hover {
      background-color: #f7f7f7;
      cursor: pointer;
    }
  }
}
