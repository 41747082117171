@import "~stylesheets/abstracts/mixins";

.input-field {
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
  display: flex;

  > label {
    position: absolute;
    background-color: transparent;
    color: gray;
    cursor: text;
    transition: top, left, font-size, font-weight 0.2s, 0.2s, 0.2s, 0.2s;

    margin: 0px 5px;
    padding: 0px 5px;
    font-size: 17px !important;
    font-weight: 400;
    top: 17px;
    left: 10px;
    pointer-events: none;
  }

  > input {
    border: none;
    font-size: 16px;
    height: 100%;
    border-radius: 5px;
    flex: 1;

    &:focus {
      outline: none;
    }
  }

  > input:-webkit-autofill,
  > input:-webkit-autofill:hover,
  > input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  &.input-error {
    border: 1px solid red;

    &::after {
      content: "Please fill this form";
      position: absolute;
      bottom: -20px;
      right: 0;
      font-size: 12px;
      color: red;

      @include for-phone-only {
        bottom: -18px;
        font-size: 10px;
      }
    }
  }
}
