@import "~stylesheets/abstracts/mixins";
.schedule-call-ctn {
  max-width: 1100px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
//   margin-top: -100px;

  p {
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat";
    color: #424242;
    margin-top: 25px;
    span {
      color: #3772e3;
      cursor: pointer;
    }

    @include for-phone-only {
      font-size: 14px;
    }
  }
}
