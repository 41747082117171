// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

body {
	background-color: #fff;
	color: #242424;
}

a {
	color: #333;
	text-decoration: none;
	transition: color 0.3s ease-in-out;

	&:hover {
		color: #242424;
		text-decoration: none;
	}
}

.parallax-absolute {
	// position: absolute;
	// top: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;
	transition: all 0.3s linear;
}
