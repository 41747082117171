.questions-popup {
  .popup-form {
    padding-bottom: 40px;
  }

  & button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-holder {
    & .field {
      width: 100%;
      margin-bottom: 15px;
      height: 38px;
    }

    & label {
      height: 38px !important;
      top: -8px;
      color: rgba(0, 0, 0, 0.37);
    }

    .MuiInputBase-root {
      .MuiOutlinedInput-input {
        height: 38px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 0 0 15px;
      }

      &.Mui-focused fieldset {
        border-width: 1px;

        &:hover {
          border-color: #3369e2 !important;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #ecebed;
      }
    }
  }
}
