@import "~stylesheets/abstracts/mixins";

.no-deals {
  display: flex;
  justify-content: center;
  align-items: center;

  @include for-tablet-landscape {
    align-items: flex-start;
    padding-top: 100px;
  }

  & .card {
    width: 270px !important;
    height: 350px;
    max-height: 420px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    & svg {
      width: 60%;
      height: 60%;
      margin-bottom: 15px;
    }

    & > div {
      text-align: center;

      & h3,
      & h5 {
        margin: 7px;
        font-family: "Montserrat";
        color: #3369e2;
      }

      & h3 {
        font-weight: 400;
      }

      & h5 {
        font-weight: 100;
        color: rgba($color: #3369e2, $alpha: 0.5);

        & a {
          color: #3369e2;
          opacity: 1;
          font-weight: 400;
          font-family: "Montserrat";
        }
      }
    }
  }
}

.is-folded {
  & .card {
    width: 100%;
  }
}