// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header {
  @include for-phone-only {
    height: 65px;
  }
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: 99;
  transition: height 0.4s ease-in-out, transform 0.4s ease-in-out;
  transform: translateY(0);
  border-bottom: 1px solid #f6f6f6;
  font-family: "Montserrat";
  background-color: #fff;

  &__inner {
    display: flex;
    align-items: center;
    // width: 100vw !important;
    // padding: 0 15px;

    .logo {
      z-index: 9;

      @include for-phone-only {
        display: none;
      }

      @include for-tablet-portrait {
        svg {
          width: 100px;
        }
      }

      svg {
        height: 30px;

        path {
          fill: #333;
        }
      }
    }
  }

  .hamburger-icon {
    @include for-phone-only {
      display: block;

      z-index: 999 !important;
    }
    cursor: pointer;
    display: none;
    position: absolute;
    left: 25px;
    margin-top: 5px;
    display: none;
    height: 40px;
    width: 40px;
    transform-origin: left center;

    & > .line {
      background-color: #333 !important;
    }

    &.is-active {
      & > .line {
        transform-origin: left center;
        background-color: #333;

        &:nth-of-type(1) {
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
          display: none;
        }

        &:nth-of-type(3) {
          transform: rotate(-45deg);
        }
      }
    }

    & > .line {
      width: 30px;
      height: 2px;
      background-color: #fff;
      display: block;
      margin-bottom: 5px;
      position: absolute;
      left: 0;
      right: 0;
      top: 19px;
      margin: auto;
      transition: transform 0.4s ease;

      &:nth-of-type(1) {
        top: 8px;
      }

      &:nth-of-type(3) {
        bottom: 8px;
        top: inherit;
      }
    }
  }
}

.hide-header {
  top: -86px;

  .user-avatar {
    top: 101px;

    .arrow-box {
      height: calc(100vh + 90px);
    }
  }
}

.logout-mobile {
  display: none !important;

  & li:last-child a::after {
    content: none !important;
  }

  @include for-phone-only {
    display: block !important;
  }
}

// .transparent-header {
//   background-color: transparent;
//   border-bottom: 1px solid transparentize($color: #fff, $amount: 0.6);

//   .navigation ul li a {
//     color: white;

//     @include for-phone-only{
//       color: #000;
//     }

//     &::after {
//       background-color: white !important;
//     }
//   }
//   .logo {
//     path {
//       fill: #fff !important;
//     }
//   }
// }

.extended-header {
  .container {
    max-width: 100% !important;
  }
}

// .blue-header {
//   background-color: #3369e2;
//   border-bottom: 1px solid transparentize($color: #fff, $amount: 0.6);

//   @include for-phone-only {
//     background-color: transparent;
//     & .hamburger-icon {
//       & .line {
//         background-color: white !important;
//       }
//     }
//   }

//   .navigation ul li a {
//     color: white;

//     @include for-phone-only {
//       color: #333;
//     }

//     &::after {
//       background-color: white !important;

//       @include for-phone-only {
//         background-color: #3369e2 !important;
//       }
//     }
//   }
//   .logo {
//     path {
//       fill: #fff !important;
//     }
//   }

//   .navigation {
//     & .cta-buttons {
//       & button {
//         background-color: #3369e2;
//         color: white;
//         border: 1px solid white;

//         &:first-child {
//           background-color: white;
//           color: #3369e2;

//           @include for-phone-only {
//             background-color: #3369e2;
//             color: white;
//           }
//         }
//       }
//     }
//   }
// }

.navigation {
  @include for-phone-only {
    position: absolute;
    padding-top: 90px;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 998;
    display: none;

    &.is-opened {
      display: block;
      transform: translateX(0);

      // & .user-avatar {
      //   display: none;
      // }

      ul {
        li {
          animation-name: fadeInLeft;
          animation-timing-function: linear;
          animation-duration: 0.4s;
          animation-fill-mode: both;

          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              animation-delay: $i * 0.2s;
            }
          }
        }
      }
    }

    .cta-buttons {
      @include for-phone-only {
        flex-direction: column;
        align-items: center;
      }
      .button {
        // background-color: #3369e2;
        // color: #fff;
        display: block;
        width: 140px;
        margin-right: 22px;

        @include for-phone-only {
          margin-bottom: 10px;
          margin-right: 0;
        }

        // &:hover {
        //   opacity: 0.6;
        //   background-color: transparentize($color: #3369e2, $amount: 0.8);
        // }
      }
    }
  }

  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ul {
    @include for-phone-only {
      line-height: 1.4;
      padding-right: 0;
      width: 100%;
    }

    padding: 0;
    margin: 0;
    list-style-type: none;
    line-height: 75px;
    flex: 1;
    text-align: center;

    li {
      &:first-of-type {
        display: none;
        @include for-phone-only {
          display: block;
        }
      }
      @include for-phone-only {
        display: block;
        line-height: 40px;
        text-align: center;
      }
      display: inline-block;
      margin: 0 12px;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1.4px;

      @include for-tablet-portrait {
        margin: 0 9px;
        font-size: 10px;
      }

      a {
        font-size: 14px;
        font-family: "Montserrat";
        font-weight: 500;
        color: #333;

        @include for-phone-only {
          color: #333;
          font-size: 22px;
          line-height: 58px;
        }

        position: relative;
        text-transform: uppercase;
        display: inline-block;
        text-decoration: none;

        &:hover {
          &::after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0;
            right: 0;
            margin: auto;
            background-color: #3369e2;
            width: 30px;
            height: 3px;

            @include for-tablet-portrait {
              bottom: -4px;
            }
          }
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0;
            right: 0;
            margin: auto;
            background-color: #3369e2;
            width: 30px;
            height: 3px;

            // @include for-tablet-portrait {
            //   bottom: -4px;
            // }
          }
        }
      }
    }
  }

  .cta-buttons {
    display: flex;
    margin-left: 40px;

    @include for-phone-only {
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }

    @include for-tablet-portrait {
      margin-left: 0px;
    }

    .button {
      // width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      padding: 0 18px;
      height: 50px;

      @include for-tablet-portrait {
        padding: 0 8px;
      }
    }

    .login-btn {
      // background: transparent;
      // color: #356FE3;
      // text-transform: none;
      // font-size: 18px;

      @include for-tablet-portrait {
        font-size: 16px;
      }
    }
  }
}

.user-avatar {
  position: relative;
  z-index: 400;
  @include for-phone-only {
    position: absolute;
    right: 25px;

    &.is-dropdown {
      width: 100vw;
      display: flex;
      justify-content: flex-end;
      z-index: 2000;

      .user-image {
        z-index: 1500;
      }
    }
  }
}

.user {
  padding-right: 30px;
  padding-left: 87px;

  .profile {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: -45px;
      top: 7px;
      width: 1px;
      height: 16px;
      background-color: #cfcfcf;
      transition: top 0.4s ease-in-out;
    }

    &__image {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom: 17px;
      margin-top: 12px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
      }

      .name {
        margin-left: 10px;
        font-size: 12px;
        color: #bbb;
        font-weight: 500;
        letter-spacing: 0.3px;
      }

      &:hover {
        nav {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.3s ease-in;

          ul {
            li {
              animation: opacityAnim 0.7s cubic-bezier(0.4, 0.01, 0.165, 0.99)
                0s 1 normal forwards;

              @for $i from 1 to 10 {
                &:nth-child(#{$i}) {
                  animation-delay: $i * 0.05s;
                }
              }
            }
          }
        }
      }

      nav {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-out;
        position: absolute;
        top: 0;
        right: -30px;
        width: 240px;
        background: #22221e;
        margin-top: 58px;
        padding: 10px 0 10px 0;
        box-shadow: 0 5px 124px 0 rgba(167, 167, 167, 0.5);

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            background: transparent;
            border-radius: 0 0 4px 4px;
            opacity: 0;

            &:not(:last-child) {
              border-bottom: 1px solid #32302c;
            }

            a {
              padding: 15px 20px;
              display: block;
              color: #dadada;
              font-size: 11px;
              line-height: 19px;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 0.2px;

              &:hover,
              &:focus {
                text-decoration: none;
                color: #bbb;
              }

              i {
                color: #8f8f8f;
                padding-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes boxShadow {
  from {
    box-shadow: 0 0 0 #333;
  }

  to {
    box-shadow: 0 8px 30px #333;
  }
}

@keyframes opacityAnim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
