@import "~stylesheets/abstracts/mixins";
@import "~stylesheets/abstracts/variables";

.list-home-details {
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 180px;
  font-family: "Montserrat";

  &.property-info {
    justify-content: flex-start;
    li {
      .property-room-value {
        font-size: 14px;
        &::before {
          margin-right: 5px;
        }
      }
    }
  }

  &.submit-property {
    justify-content: flex-start;
    margin-top: 25px;
    flex-wrap: wrap;
    min-width: unset;

    li {
      margin-right: 10px;
      margin-left: 0;

      .property-room-value {
        font-size: 16px;
        &::before {
          width: 6px;
          height: 6px;
        }

        @include for-phone-only {
          font-size: 12px;
          &::before {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    .property-room-value {
      &::before {
        content: "\A";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-image: $gradient-bg;
        display: inline-block;
        margin-bottom: 3px;
        margin-right: 10px;
      }
      display: inline-block;
      font-size: 12px;
      font-weight: 600;

      @include for-phone-only {
        font-size: 12px;
      }
    }
  }
}
