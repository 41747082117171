.popup {
  transition: opacity 0.4s ease-in-out;
  display: inline-flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .popup-form {
    animation-name: smothScale;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  .close-popup {
    position: absolute;
    top: 8px;
    right: 14px;
    font-size: 30px;
    color: #d8d8d8;
    cursor: pointer;
    z-index: 9;

    &:hover {
      color: transparentize($color: #3369e2, $amount: 0.2);
    }
  }
}
