// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Montserrat", sans-serif !default;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: #3369e2 !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Dark
/// @type Color
$dark: rgb(0, 0, 0) !default;

/// Linear
/// @type Color
$gradient-bg: linear-gradient(to left, #4697e8, #3369e2) !default;

/// Container's maximum width
/// @type Length
$max-width: 1520px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels

$breakpoints: (
  "small": 320px,
  "medium": 768px,
  "large": 1024px,
) !default;
