.marker {
  z-index: 0;
  cursor: pointer;

  & .property-pin {
    &::after {
      background: #3369e2 !important;
    }
  }

  & .blue-pin {
    &::after {
      background: red !important;
    }
  }
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  z-index: -10 !important;

  &::after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #3369e2;
    position: absolute;
    border-radius: 50%;
    z-index: -10 !important;
  }
}

.property-pin {
  &::after {
    background: red !important;
  }
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
