@import "~stylesheets/abstracts/mixins";

.popup-form {
  background-color: #fff;
  border-radius: 5px;
  width: 560px;
  padding: 20px;
  margin-bottom: 15px;
  font-family: "Montserrat";

  .button-cnt {
    & button {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  &.is-page {
    opacity: 1;
    // margin: auto;
  }

  &.login-form {
    width: 500px;
    padding: 85px 45px;

    @include for-phone-only {
      width: 90vw;
      max-width: 400px;
      padding: 40px 20px;
    }
  }

  &.email-confirm {
    padding: 50px 45px;
  }

  .logo {
    text-align: center;
    padding-bottom: 50px;

    &.no-padding {
      padding-bottom: 0;
    }
  }

  .form-holder {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    p {
      margin-bottom: 20px;
      font-size: 14px;
      text-align: center;

      &.confirm-text {
        font-size: 18px;
        font-weight: 500;
        color: #222222;
        margin-bottom: 25px;

        &.margin-top {
          margin: 15px 0 0 0;
        }
      }
    }

    h2 {
      &.confirm-message {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
      }
    }

    &.align-center {
      align-items: center;
    }

    &.blue {
      h2 {
        &.confirm-message {
          color: #346ce2;
        }
      }
    }

    &.red {
      h2 {
        &.confirm-message {
          color: #f43d61;
        }
      }
    }

    .form-input {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .input-field {
        width: 100%;
        margin-bottom: 20px;
        input {
          margin-bottom: 0 !important;
        }
      }

      &.half {
        .input-ctn {
          position: relative;
          width: 100%;
        }
        > * {
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }

      input:not([type="checkbox"]),
      select,
      button {
        width: 100%;
        height: 54px;
        margin-bottom: 20px;
        border: 0;
        outline: 0;
        border: 1px solid #ecebed;
        padding: 0 10px;
        border-radius: 4px;
      }

      .checkbox {
        margin-bottom: 10px;
        // width: 100%;

        input[type="checkbox"] {
          padding: 0;
        }
      }

      .forget-password {
        // width: 50%;
        text-align: right;
        font-size: 16px;
        margin-bottom: 20px;

        @include for-phone-only {
          font-size: 12px;
        }

        .button-switch {
          cursor: pointer;

          &:hover {
            color: #3369e2;
          }
        }
      }

      label {
        font-size: 12px;
        margin-left: 10px;
        user-select: none;
      }

      .show-password {
        position: absolute;
        right: 0;
        top: calc((54px - 10px) / 2);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        cursor: pointer;

        &.is-input-plain {
          svg {
            path {
              fill: #3369e2;
            }
          }
        }
      }
    }
  }
}

.register-mobile {
  @include for-phone-only {
    width: 90vw !important;
    margin-top: 50px;
  }
}

.select-holder {
  margin-bottom: 10px;

  & .css-yk16xz-control {
    border-color: #ecebed;
  }

  .css-1okebmr-indicatorSeparator {
    background-color: #ecebed;
  }
}

.caption-connect {
  text-align: center;
  display: inline-block;
  text-align: center;
  padding: 20px 30px;

  &.is-centered {
    width: 100%;
    text-align: center;
  }

  &.bottom-gap {
    padding: 30px 0 0;
  }

  .button-switch {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: #3369e2;
  }
}

.connect-with-social-media {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    width: 50px;
    height: 50px;
    font-size: 20px;
    display: flex;
    background-color: transparentize($color: #3369e2, $amount: 0.9);
    color: transparentize($color: #3369e2, $amount: 0.1);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    &:hover {
      background-color: transparentize($color: #3369e2, $amount: 0.2);
      color: #fff;
    }
  }
}

.confirm-acc-alert {
  text-align: left;
  font-size: 11px;
  opacity: 0.7;

  & a {
    color: #3369e2;
  }
}

.popup_message {
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat";
  color: #424242;
  margin-top: 25px;
  margin-bottom: 10px;

  span {
    color: #3772e3;
    cursor: pointer;
  }

  @include for-phone-only {
    font-size: 14px;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
}
