@import "~stylesheets/abstracts/mixins";

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 299;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f9fc;
  padding-top: 120px;

  @include for-phone-only {
    padding-top: 60px;
  }

  .close-button {
    position: fixed;
    width: 50px;
    height: 50px;
    top: 20px;
    right: 20px;
    background-color: white;
    border: 1px solid #ecebed;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .close-button {
    & g {
      opacity: 1;
    }

    & svg {
      line,
      path {
        stroke: #3369e2;

        stroke-width: 2px;
      }
    }
  }

  .close-button-ctn {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(#346ce4, 0.25);

    @include for-phone-only {
      height: 50px;
      border-bottom: 1px solid rgba(#346ce4, 0.15);
    }

    div {
      font-family: "Montserrat";
      margin-right: 80px;
      font-size: 18px;
      font-weight: 500;
      color: #3673e7;
      cursor: pointer;

      @include for-phone-only {
        margin-right: 20px;
        font-size: 14px;
      }
    }
  }
}
