/**
 * Basic typography style for copy text
 */
 
body {
  color: $text-color;
  font-size: 14px;
  font-family: $text-font-stack;
}

h1, .h1 {
  font-size: 36px;
  line-height: 1.4;
}

h2, .h2 {
  font-size: 30px;
  line-height: 1.3;
}